import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';
import logo from '../assets/img/logo.svg';

const WIDGET_API_URL = 'https://backend.estate.hart-digital.com';

const createWidget = async (logoUrl) => {
  const widgetApiHandler = new ApiStore(WIDGET_API_URL)

  const searchParams = (new URL(document.location)).searchParams;
  const planId = searchParams.get('id');
  const crmPlanId = searchParams.get('crmPlanId');


  const planData = crmPlanId ? await widgetApiHandler.loadCrmWidgetData(crmPlanId) : await widgetApiHandler.loadWidgetData(planId);
  const options = {
    ...planData.parsed,
    API_URL: WIDGET_API_URL,
    rotationMode: rotationModes.DEFAULT,
    logo: logo,
    tabs: ['panorama', 'rotation'],
    locale: 'en',
    logoUrl,
    dictionaryOverrides: {
      'made-by-link': 'http://z688.pautinaweb.ru/buyhome/',
      'made-by-text': 'BuyHome'
    }
  }

  new Widget('#widget', options);
}

createWidget('http://z688.pautinaweb.ru/buyhome/');
